import React from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledVerticalGrid = styled(Grid)(({ theme }) => ({
  '&.containerGrid': {
    height: '100%',
    background: theme.palette.common.white,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  },
  '& .rideContent': {
    padding: theme.spacing(1, 2),
  },
  '& .rideTitle': {
    alignSelf: 'center',
  },
}));

const StyledHorizontalGrid = styled(Grid)(({ theme }) => ({
  '&.containerGrid': {
    background: theme.palette.common.white,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    alignItems: 'start',
  },
  '& .rideContent': {
    padding: theme.spacing(1, 2),
  },
  '& .rideTitle': {
    alignSelf: 'center',
  },
}));

const RideCardSkeleton = (props) => {
  const { card } = props;
  return (
    <>
      <StyledHorizontalGrid container className="containerGrid">
        {card == 'horiztontal' && (
          <>
            <Grid item xs={12} md={12} lg={3}>
              <Box p={2}>
                <Skeleton variant="rectangular" width="100%" height={190} />
              </Box>
            </Grid>

            <Grid container md={12} lg={9} className="rideContent">
              <Grid item xs={9} lg={12} className="rideTitle">
                <Skeleton variant="text" width="80%" height={40} />
              </Grid>

              <Grid item xs={3} lg={4}>
                <Skeleton variant="text" width="100%" height={40} />
              </Grid>
            </Grid>
          </>
        )}
      </StyledHorizontalGrid>
      <StyledVerticalGrid container className="containerGrid">
        {card == 'vertical' && (
          <Grid container>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rectangular" width="100%" height={230} />
            </Grid>
            <Grid container md={12} className="rideContent">
              <Grid item xs={11}>
                <Skeleton variant="text" width="80%" height={40} />
              </Grid>
              <Grid item xs={1}>
                <Skeleton variant="text" width="100%" height={40} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </StyledVerticalGrid>
    </>
  );
};
export { RideCardSkeleton };
