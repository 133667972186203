import React from 'react';
import {
  Typography, Box, Grid, Button,
} from '@mui/material';
import StyledBox, {
  StyledImgBox,
} from 'views/common/components/UI/OffRoadPromotionBanner/OffRoadPromotionBanner.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import { Typography700 } from 'views/common/components';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import PATHS from 'lib/navigation/paths';

const OffRoadPromotionBanner = () => {
  const { t } = useTranslation('fe_er_common_off_road_promotion_banner');
  const bgImg = {
    large: '/imgs/promotionLG.png',
    medium: '/imgs/promotionMd.png',
    small: '/imgs/promotionSM.png',
    alt: 'background-img',
  };

  return (
    <StyledBox>
      <Grid container className="ORPBGridContainer">
        <Grid item xs={12} md={6} lg={6}>
          <Box className="showMOBILE ORPBImgContainer">
            <StyledImgBox
              image={{
                src: bgImg.small,
                alt: bgImg.alt,
              }}
              imgWidth="100%"
              imgHeight="100%"
              objectFit="cover"
            />
          </Box>
          <Box className="showTABLET ORPBImgContainer">
            <StyledImgBox
              image={{
                src: bgImg.medium,
                alt: bgImg.alt,
              }}
              imgWidth="100%"
              imgHeight="100%"
              objectFit="cover"
            />
          </Box>
          <Box className="showDESKTOP ORPBImgContainer">
            <StyledImgBox
              image={{
                src: bgImg.large,
                alt: bgImg.alt,
              }}
              imgWidth="100%"
              imgHeight="100%"
              objectFit="cover"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box className="ORPBTextContainer">
            <Typography500 className="ORPBTitle">
              {t('fe_er_common_off_road_promotion_banner:title')}
            </Typography500>
            <Typography700 className="ORPBSubTitle" mb={1}>
              {t('fe_er_common_off_road_promotion_banner:sub_title')}
            </Typography700>
            <Typography className="showDESKTOP" variant="body1">
              {t('fe_er_common_off_road_promotion_banner:description')}
            </Typography>
            <Button
              component={NavigatorLink}
              variant="contained"
              className="ORPBBtn"
              href={PATHS.guidedOffroadTour.index()}
              color="primary">
              {t('fe_er_common_off_road_promotion_banner:book_now')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export { OffRoadPromotionBanner };
