import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography500 from 'views/common/components/UI/Typography500';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.la-accordionRoot': {
    boxShadow: 'none',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.secondary.dark,
    borderRadius: 0,
    lineHeight: theme.spacing(2),
  },
  '& .la-accordionDetails': {
    paddingTop: 0,
    '& p': {
      lineHeight: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      paddingTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
      fontWeight: '400',
    },
  },
  '& .la-limited': {
    padding: theme.spacing(0.5, 2),
    display: 'flex',
    minHeight: 0,
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& .la-expandIcon': {
      width: theme.spacing(2),
      cursor: 'pointer',
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0,
    },
  },
}));

const LimitedAvailability = () => {
  const { t } = useTranslation('fe_er_common_rental_card');

  return (
    <StyledAccordion className="la-accordionRoot">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="la-expandIcon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="la-limited">
        <Typography500 component="p" variant="h6">
          {t('fe_er_common_rental_card:limited_availability')}
        </Typography500>
      </AccordionSummary>
      <AccordionDetails className="la-accordionDetails">
        <Typography component="p" variant="h6">
          {t('fe_er_common_rental_card:limited_availability_details')}
        </Typography>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export { LimitedAvailability };
