import React from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.root': {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.common.white,
    minHeight: theme.spacing(70),
  },
  '& .title': {
    padding: theme.spacing(3, 2),
  },
  '& .container': {
    padding: theme.spacing(3.3, 2),
    alignItems: 'center',
  },
}));

const MotorcycleRentalCardSkeleton = () => (
  <StyledBox className="root">
    <Box className="title">
      <Skeleton variant="text" width="70%" height={40} />
    </Box>
    <Skeleton variant="rectangular" width="100%" height={230} />
    <Grid container className="container">
      <Grid item xs={12}>
        <Skeleton variant="text" width="100%" height={60} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" width="70%" height={30} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" width="70%" height={30} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" width="70%" height={30} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" width="70%" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" width="100%" height={60} />
      </Grid>
    </Grid>
  </StyledBox>
);
export { MotorcycleRentalCardSkeleton };
