import React from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.root': {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.common.white,
  },
  '& .title': {
    padding: theme.spacing(3, 2),
  },
  '& .bookbtn': {
    display: 'flex',
    justifyContent: 'end',
  },
  '& .container': {
    padding: theme.spacing(3.3, 2),
    alignItems: 'center',
  },
}));

const DealCardSkeleton = () => (
  <StyledRoot className="root">
    <Box className="title">
      <Skeleton variant="text" width="70%" height={40} />
    </Box>
    <Skeleton variant="rectangular" width="100%" height={230} />
    <Grid container className="container">
      <Grid item xs={6}>
        <Skeleton variant="text" width="50%" height={40} />
      </Grid>
      <Grid item className="bookbtn" xs={6}>
        <Skeleton variant="text" width="80%" height={40} />
      </Grid>
    </Grid>
  </StyledRoot>
);
export { DealCardSkeleton };
