import React from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.root': {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.common.white,
  },
  '& .title': {
    padding: theme.spacing(2, 2, 0),
  },
  '& .container': {
    padding: theme.spacing(0, 2, 2),
    alignItems: 'center',
  },
}));

const TourCardSkeleton = () => (
  <StyledBox className="root">
    <Skeleton variant="rectangular" width="100%" height={230} />
    <Box className="title">
      <Skeleton variant="text" width="70%" height={40} />
    </Box>
    <Grid container className="container">
      <Grid item xs={12}>
        <Skeleton variant="text" width="100%" height={40} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" width="70%" height={30} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="text" width="70%" height={30} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" width="100%" height={60} />
      </Grid>
    </Grid>
  </StyledBox>
);
export { TourCardSkeleton };
