import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '&.nmp-nonMemberGrid': {
    margin: theme.spacing(2, 0),
  },
  '& .nmp-nonMemberGridItem': {
    paddingLeft: theme.spacing(2),
    position: 'relative',
    '&:before': {
      content: "''",
      position: 'absolute',
      borderRight: `1px solid ${theme.palette.grey['100']}`,
      height: '70%',
      width: '1px',
      left: 0,
      top: '15%',
    },
    '&.nmp-primary': {
      color: theme.palette.primary.main,
    },
  },
  '& .nmp-smallText': {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '400',
    lineHeight: theme.spacing(2),
  },
  '& .nmp-nonMemberText': {
    color: theme.palette.secondary.main,
  },
  '& .nmp-grey300': {
    color: theme.palette.grey['300'],
  },
  '& .nmp-per': {
    marginLeft: theme.spacing(0.5),
  },
}));

const NonMemberPrices = (props) => {
  const { price, daysNum } = props;
  const { t } = useTranslation('fe_er_common_rental_card');

  return (
    <StyledGrid container className="nmp-nonMemberGrid">
      <Grid item xs={6} className="nmp-nonMemberGridItem">
        <Typography component="p" className="nmp-nonMemberText nmp-smallText">
          {t('fe_er_common_rental_card:non_member_price')}
        </Typography>
        <Typography variant="h5" component="span" className="nmp-price">
          {price}
          <Typography
            component="span"
            className="nmp-grey300 nmp-per nmp-smallText">
            {t('fe_er_common_rental_card:per_day')}
          </Typography>
        </Typography>
        <Typography className="nmp-smallText nmp-grey300" component="p">
          {`${daysNum} ${t('fe_er_common_rental_card:days')}`}
        </Typography>
      </Grid>
      <Grid item xs={6} className="nmp-nonMemberGridItem nmp-primary">
        <Typography component="p" className="smallText">
          {t('fe_er_common_rental_card:member_price')}
        </Typography>
        <Typography500 component="p" variant="h5" className="">
          {`${daysNum} ${t('fe_er_common_rental_card:credit')}`}
        </Typography500>
        <Typography component="p" className="nmp-smallText">
          {`${daysNum} ${t('fe_er_common_rental_card:credit')} / ${t(
            'fe_er_common_rental_card:day',
          )}`}
        </Typography>
      </Grid>
    </StyledGrid>
  );
};

export { NonMemberPrices };
