import React from 'react';
import { Typography, Grid } from '@mui/material';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { useTranslation } from 'next-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  StyledGrid,
  StyledImgBox,
} from 'views/common/components/UI/RideCard/RideCard.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const RideCard = (props) => {
  const { t } = useTranslation('fe_er_ride_card');
  const {
    image,
    title,
    subtitle,
    url,
    className,
    address,
    titleComponent = 'h3',
    titleVariant = 'h4',
  } = props;

  return (
    <NavigatorLink href={url} className={className}>
      <StyledGrid container className="containerGrid" subtitle={subtitle}>
        <Grid item lg={subtitle ? 3 : 12} xs={12} md={12} p={subtitle ? 2 : 0}>
          <StyledImgBox image={image} imgHeight="12rem" subtitle={subtitle} />
        </Grid>
        <Grid lg={subtitle ? 9 : 12} xs={12} md={12} className="rideContent">
          <Grid container>
            <Grid item xs={subtitle ? 12 : 10} className="rideTitle">
              <Typography500
                component={titleComponent}
                variant={titleVariant}
                className="RCMainTitle">
                {title}
              </Typography500>
              {address && subtitle && (
                <Typography component="p" variant="body2" className="RCAddress">
                  <LocationOnIcon />
                  <Typography component="span" variant="body2" pl={0.5}>
                    {address}
                  </Typography>
                </Typography>
              )}
              {subtitle && (
                <Typography
                  className="RCDesc"
                  component="p"
                  variant="body2"
                  mb={2}
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={subtitle ? 12 : 2}
              className={subtitle ? '' : 'gridWithoutDesciption'}>
              {subtitle ? (
                <NavigatorLink href={url} className="detailbtn">
                  <Typography
                    varient="h6"
                    component="span"
                    className="detailbtntext">
                    {t('fe_er_ride_card:view_details')}
                  </Typography>
                  <ArrowForwardIosIcon className="detailbtnicon" />
                </NavigatorLink>
              ) : (
                <NavigatorLink href={url} className="detailedBtnIconOnly">
                  <ArrowForwardIosIcon className="detailbtnicon iconOnly" />
                </NavigatorLink>
              )}
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>
    </NavigatorLink>
  );
};
export { RideCard };
