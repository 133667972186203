import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { styled } from '@mui/material/styles';

const StyledNavigatorLink = styled(NavigatorLink)(({ theme }) => ({
  '&.root': {
    background: theme.palette.common.white,
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.12)',
    marginTop: '2px',
    marginLeft: '2px',
    borderRadius: '4px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transition: 'box-shadow 0.2s ease-out, transform 0.2s ease-out',
    height: '100%',
    '&.labeledRoot:hover': {
      transition: 'box-shadow 0.25s ease-in-out, transform 0.25s ease-in-out',
      boxShadow:
        '0 2px 4px 0 rgba(1, 154, 149, 0.24), 0 0 0 2px rgb(0 175 170)',
    },
  },
  '& .ECimg': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: theme.spacing(24),
  },
  '& .cardFooterBox': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1),
    },
  },
  '& .detailsBox': {
    paddingRight: theme.spacing(2),
  },
  '& .eventName': {
    color: theme.palette.secondary.main,
  },
  '& .eventDate': {
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(14),
  },
  '& .eventSubtitle': {
    fontWeight: '400',
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(0.5),
  },
  '& .endDateLabel': {
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    width: theme.spacing(8),
    minWidth: theme.spacing(8),
    height: theme.spacing(7.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    '& p': {
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'capitalize',
    },
  },
  '& .feature': {
    position: 'absolute',
    right: '0',
    background: theme.palette.info.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.24, 1),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.spacing(1.76),
    zIndex: 1,
  },
}));

export default StyledNavigatorLink;
