import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const PageTitleSkeleton = () => (
  <>
    <Skeleton variant="text" width="40%" height={40} />
    <Skeleton variant="text" width="60%" height={40} />
  </>
);

export { PageTitleSkeleton };
