import React, { useState } from 'react';
import {
  styled,
  Box,
  Typography,
  Button,
  IconButton,
  useTheme,
} from '@mui/material';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import StarIcon from '@mui/icons-material/Star';
import IconSpecs from 'views/common/components/UI/Icons/IconSpecs';
import { useTranslation } from 'next-i18next';
import { ImgBox } from 'views/common/components';
import {
  LimitedAvailability,
  MoreDiscountRates,
  MemberPrices,
  NonMemberPrices,
  BikeDetails,
  ClubPrice,
} from 'views/common/components/UI/MotorcycleRentalCard';
import ReactCardFlip from 'react-card-flip';
import Typography500 from 'views/common/components/UI/Typography500';
import { useSelector } from 'react-redux';

const StyledBox = styled(Box)(({ theme /* , member */ }) => ({
  '&.mrc-rentalCardRoot': {
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    margin: 'auto',
    borderRadius: '6px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.12)',
    marginTop: '2px',
    marginLeft: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    // minHeight: member ? theme.spacing(72) : theme.spacing(69),
    position: 'relative',
    '& .la-accordionRoot': {
      // limited availabilty
      position: 'relative',
      zIndex: 2,
    },
  },
  '& .react-card-flip': {
    height: '100%',
  },
  '& .react-card-back, & .react-card-front': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  '& .mrc-motor': {
    position: 'relative',
  },
  '& .mrc-motorTop': {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',
      height: '100%',
      alignItems: 'center',
    },
  },
  '& .mrc-motorTitleHolder': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    minHeight: theme.spacing(10.25),
  },
  '& .mrc-imgBox': {
    margin: theme.spacing(2, 0),
  },
  '& .mrc-specsHolder': {
    padding: 0,
    marginLeft: theme.spacing(2),
    position: 'relative',
    zIndex: 2,
  },
  '& .mrc-specsMd': {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    borderRadius: '50%',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    cursor: 'pointer',
    '& svg': {
      marginTop: theme.spacing(1.125),
      color: 'inherit',
      height: theme.spacing(2),
    },
  },
  '& .mrc-specsLg': {
    color: theme.palette.grey['400'],
    letterSpacing: '1px',
    marginTop: theme.spacing(1.125),
    '& svg': {
      marginRight: theme.spacing(1),
      height: theme.spacing(1.75),
    },
  },
  '& .mrc-motorInfoHolder': {
    padding: theme.spacing(0, 2, 1),
  },
  '& .mrc-grey300': {
    color: theme.palette.grey['300'],
  },
  '& .mrc-badges': {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  '& .mrc-badge': {
    backgroundColor: theme.palette.grey['50'],
    border: `1px solid ${theme.palette.grey['100']}`,
    padding: theme.spacing(0.375, 1.125),
    borderRadius: theme.typography.pxToRem(11),
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "'Roboto', sans-serif",
    lineHeight: 'normal',
    '& svg': {
      height: theme.typography.pxToRem(16),
      width: theme.typography.pxToRem(16),
      marginLeft: theme.spacing(0.5),
      color: theme.palette.warning.main,
    },
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  '& .mrc-bikeLink': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  '& .mrc-smallText': {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '400',
    lineHeight: theme.spacing(2),
  },
  '& .mrc-divider': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const MotorcycleRentalCard = (props) => {
  const {
    id,
    name,
    location,
    rating,
    tripsCount,
    limitedAvailability,
    price,
    image,
    discountsArr = [],
    member,
    modelAttributes,
    bikePageurl,
    daysNum,
    discountPercentage,
    spinningCard,
    priceInCredits,
    showReservationBtn = true,
    handleCardClick = () => {},
  } = props;

  const showMembershipPromotion = useSelector(
    (state) => state.globalData.showMembership,
  );

  const theme = useTheme();
  const { t } = useTranslation('fe_er_common_rental_card');
  const [isShowDetails, setIsShowDetails] = useState(false);

  return (
    <StyledBox className="mrc-rentalCardRoot" member={member}>
      <ReactCardFlip isFlipped={isShowDetails} flipDirection="horizontal">
        <>
          <NavigatorLink
            onClick={() => {
              handleCardClick({ id, name, image });
            }}
            href={bikePageurl}
            translate={bikePageurl?.translate}
            className="mrc-bikeLink"
          />
          <div className="mrc-motor">
            <div className="mrc-motorTitleHolder">
              <div className="mrc-motorTitle">
                <Typography500 variant="h5" component="p">
                  {name}
                </Typography500>
                <Typography variant="h5" className="mrc-grey300">
                  {location}
                </Typography>

                {(rating || tripsCount > 0) && (
                  <div className="mrc-badges">
                    {!!rating && rating > 0 && (
                      <span className="mrc-badge">
                        <span>{rating}</span>
                        <StarIcon />
                      </span>
                    )}
                    {tripsCount > 0 && (
                      <span className="mrc-badge">
                        {tripsCount}
                        {' '}
                        {t('fe_er_common_rental_card:trips')}
                      </span>
                    )}
                  </div>
                )}
              </div>
              {spinningCard && (
                <IconButton
                  onClick={() => setIsShowDetails(true)}
                  className="mrc-specsHolder">
                  <div className="mrc-specsMd">
                    <IconSpecs />
                  </div>
                </IconButton>
              )}
            </div>
            <ImgBox
              classes="mrc-imgBox"
              image={{ src: image.src, alt: image.alt }}
              imgHeight={theme.spacing(22)}
              imgObjectFit="contain"
            />
            {limitedAvailability && <LimitedAvailability />}
          </div>
          <div className="mrc-motorInfoHolder">
            {/* MORE DISCOUNT RATES */}
            <MoreDiscountRates price={price} discountsArr={discountsArr} />
            {/* NOT MEMEBER */}
            {!member && !discountsArr.length && price && (
              <NonMemberPrices price={price} daysNum={daysNum} />
            )}
            {!member && showReservationBtn && (
              <Button
                fullWidth
                color="primary"
                className="mrc-reserveBtn"
                component={NavigatorLink}
                href={bikePageurl}
                variant="contained">
                {t('fe_er_common_rental_card:reserve_now')}
              </Button>
            )}
          </div>
          {/* MEMBER GOES HERE */}
          {member && (
            <MemberPrices
              price={price}
              discountPercentage={discountPercentage}
            />
          )}
        </>

        <BikeDetails
          close={() => setIsShowDetails(false)}
          name={name}
          modelAttributes={modelAttributes}
          bikePageurl={bikePageurl}
        />
      </ReactCardFlip>
      {false && showMembershipPromotion && priceInCredits > 0 && (
        <ClubPrice numberOfCredits={priceInCredits} />
      )}
    </StyledBox>
  );
};

export { MotorcycleRentalCard };
