import React from 'react';
import {
  Grid, Box, useMediaQuery, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.mainWidgetSkeleton': {
    '& .MuiSkeleton-root': {
      backgroundColor: theme.palette.grey[50],
      marginBottom: theme.spacing(1.4),
    },
    '& .medialItem': {
      marginBottom: theme.spacing(1.9),
      borderRadius: theme.spacing(0.25),
      '&.rememberMe': {
        borderRadius: theme.spacing(0),
      },
    },
    '& .button': {
      borderRadius: theme.spacing(0.5),
    },
    '& .announcment': {
      borderRadius: theme.spacing(1),
    },
  },
}));

const MainWidgetSkeleton = (props) => {
  const { vertical } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <StyledRoot className="mainWidgetSkeleton" vertical={vertical}>
      {!vertical && !isMobile ? (
        <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              className="medialItem"
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              className="medialItem"
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              className="medialItem"
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              className="medialItem"
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              className="medialItem"
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              className="button"
            />
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Skeleton variant="rectangular" width="100%" height="56px" />
          <Skeleton
            variant="rectangular"
            width="98%"
            height={54}
            className="medialItem"
          />
          <Skeleton
            variant="rectangular"
            width="98%"
            height={24}
            className="medialItem rememberMe"
          />
          <Skeleton
            variant="rectangular"
            width="98%"
            height={54}
            className="medialItem"
          />
          <Skeleton
            variant="rectangular"
            width="98%"
            height={54}
            className="medialItem"
          />
          <Skeleton
            variant="rectangular"
            width="98%"
            height={54}
            className="medialItem"
          />
          <Skeleton
            variant="rectangular"
            width="98%"
            height={40}
            className="medialItem"
          />
          <Skeleton
            variant="rectangular"
            width="98%"
            height={36}
            className="button"
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            className="announcment"
          />
        </Box>
      )}
    </StyledRoot>
  );
};
export { MainWidgetSkeleton };
