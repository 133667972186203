import React from 'react';
import { Typography, Box } from '@mui/material';
import StyledNavigatorLink from 'views/common/components/UI/EventCard/EventCard.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import { ImgBox } from 'views/common/components';

const EventCard = (props) => {
  const {
    image,
    title,
    subtitle,
    startDate,
    endDate,
    endDateObject,
    label,
    url,
    className,
  } = props;
  return (
    <StyledNavigatorLink
      className={`root ${label ? 'labeledRoot' : ''} ${className}`}
      href={url}>
      {label && (
        <Typography500 varient="h6" className="feature" component="p">
          {label}
        </Typography500>
      )}
      <Box className="ECimg">
        <ImgBox image={{ src: image, alt: title }} imgHeight="100%" />
      </Box>
      <Box className="cardFooterBox">
        <Box className="detailsBox">
          <Typography500 varient="h5" component="p" className="eventName">
            {title}
          </Typography500>
          {startDate && (
            <Typography500 varient="h6" className="eventDate">
              {`${startDate} - ${endDate}`}
            </Typography500>
          )}
          {subtitle && (
            <Typography varient="h6" className="eventSubtitle" component="p">
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box className="endDateLabel">
          <Typography500 varient="h6" component="p">
            {endDateObject.day}
            {' '}
            <br />
            {endDateObject.month}
          </Typography500>
        </Box>
      </Box>
    </StyledNavigatorLink>
  );
};
export { EventCard };
