import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components/UI/ImgBox';

export const StyledGrid = styled(Grid)(({ theme, subtitle }) => ({
  '&.containerGrid': {
    alignItems: 'start',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: ' 4px',
    backgroundColor: theme.palette.common.white,
  },
  '& .rideContent': {
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingTop: subtitle ? theme.spacing(0) : theme.spacing(2),
    },
  },
  '& .rideTitle': {
    color: theme.palette.grey[400],
  },
  '& .detailbtn': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .detailbtntext': {
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
  },
  '& .gridWithoutDesciption': {
    display: 'flex',
    justifyContent: 'end',
  },
  '& .detailbtnicon': {
    height: theme.spacing(1.5),
  },
  '& .detailedBtnIconOnly': {
    '& .iconOnly': {
      color: theme.palette.secondary.main,
      verticalAlign: 'middle',
    },
  },
  '& .RCMainTitle': {
    color: theme.palette.secondary.dark,
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(1),
    },
  },
  '& .RCAddress': {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(3),
      color: theme.palette.secondary.light,
    },
  },
  '& .RCDesc': {
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    maxHeight: theme.spacing(8.625), // lineheight@line numbers
    [theme.breakpoints.down('md')]: {
      maxHeight: theme.spacing(11.5), // lineheight@line numbers
    },
  },
}));
export const StyledImgBox = styled(ImgBox)(({ theme, subtitle }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('lg')]: {
      height: subtitle ? theme.spacing(46.375) : theme.spacing(23.625),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(23.625),
    },
  },
}));
