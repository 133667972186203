import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { styled } from '@mui/material/styles';

const StyledNavigatorLink = styled(NavigatorLink)(({ theme }) => ({
  '&.root': {
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.12)',
    marginTop: '2px',
    marginLeft: '2px',
    backgroundColor: theme.palette.common.white,
    minHeight: '100%',
    display: 'flex',
    flexFlow: 'column',
    borderRadius: theme.spacing(0.5),
    transition: 'all 0.05s ease-in-out',
    border: '1px solid transparent',
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
  },
  '& .title': {
    padding: theme.spacing(0, 2),
    lineHeight: 1.3,
    height: theme.spacing(9.625),
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: theme.palette.secondary.main,
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  '& .bookbtn': {
    display: 'flex',
    justifyContent: 'end',
  },
  '& .oldValue': {
    color: theme.palette.secondary.light,
    margin: 'auto 0',
    padding: theme.spacing(0.875),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '400',
    textDecoration: 'line-through',
    textAlign: 'center',
  },
  '& .dealValue': {
    display: 'inline-block',
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.A000,
    borderRadius: '2px',
    margin: 'auto 0',
    padding: theme.spacing(0.875),
  },
  '& .container': {
    padding: theme.spacing(1, 2, 3.3, 2),
    alignItems: 'flex-end',
  },
  '& .infoWrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  '& .rideDetails': {
    padding: theme.spacing(0, 2),
    textTransform: 'capitalize',
    color: theme.palette.grey[400],
    '& .detailsValue': {
      fontWeight: '400',
      marginLeft: theme.spacing(1),
      color: theme.palette.grey[300],
    },
  },
  '& .dealLocation': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  '& .DCfeaturedText': {
    position: 'absolute',
    right: 0,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.18, 1),
    textTransform: 'uppercase',
  },
  '& .DCfeatured': {
    position: 'relative',
    height: theme.spacing(1.8),
  },
  '& .DCPrimaryColor': {
    color: `${theme.palette.primary.main} !important`,
  },
  '& .DCImgContainer': {
    height: theme.typography.pxToRem(187),
    position: 'relative',
    overflow: 'hidden',
  },
  '& .DCImgMask': {
    width: theme.typography.pxToRem(275),
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    opacity: 0.9,
    borderRight: `${theme.typography.pxToRem(58.25)} solid transparent`,
    borderTop: `${theme.typography.pxToRem(187)} solid ${
      theme.palette.primary.main
    }`,
    [theme.breakpoints.down('lg')]: {
      width: theme.typography.pxToRem(250),
    },
  },
  '& .DCfeaturedInfo': {
    position: 'absolute',
    left: theme.spacing(2),
    zIndex: 2,
    color: theme.palette.common.white,
    bottom: '50%',
    transform: 'translateY(50%)',
  },
  '& .DCSaveText': {
    lineHeight: theme.spacing(2),
    letterSpacing: theme.typography.pxToRem(0.4),
    textTransform: 'uppercase',
    width: theme.typography.pxToRem(189),
    textDecoration: 'line-through',
  },
  '& .DCSavedValue': {
    width: theme.typography.pxToRem(189),
  },
  '& .DCSaveUpText': {
    lineHeight: theme.spacing(2),
    letterSpacing: theme.typography.pxToRem(0.4),
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
  },
  '& .DCfeaturedTitle': {
    alignItems: 'flex-start',
    paddingTop: theme.spacing(2),
  },
}));
export default StyledNavigatorLink;
