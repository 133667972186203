import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.bd-bikeDetailsContainer': {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    position: 'absolute',
    width: '100%',
    borderRadius: '6px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.12)',
  },
  '& .bd-headerBox': {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    boxSizing: 'border-box',
    '& svg': {
      cursor: 'pointer',
    },
  },
  '& .bd-bikeDetailsTable': {
    padding: theme.spacing(2),
    flex: '1 1',
    overflowY: 'auto',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track ': {
      background: theme.palette.grey[400],
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(0.5),
    },
    '& .MuiTableCell-root': {
      maxWidth: '100%',
      display: 'flex',
      color: theme.palette.common.white,
      justifyContent: 'space-between',
      padding: theme.spacing(1, 0),
      fontSize: theme.typography.pxToRem(14),
      fontWeight: '300',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
  },
  '& .bd-reserveBtnBox': {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    // position: 'fixed',
    justifySelf: 'flex-end',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
  },
}));

const BikeDetails = (props) => {
  const {
    close, name, bikePageurl, modelAttributes = [],
  } = props;
  const { t } = useTranslation('fe_er_common_rental_card');

  return (
    <StyledBox className="bd-bikeDetailsContainer">
      <Box className="bd-headerBox">
        <Typography variant="h4" component="p">
          {name}
        </Typography>
        <CloseIcon onClick={close} />
      </Box>

      <Table aria-label="sticky table" className="bd-bikeDetailsTable">
        {modelAttributes.length > 0
          && modelAttributes.map((attribute) => (
            <TableRow key={attribute.value}>
              <TableCell key={attribute.value}>
                <Typography component="p" variant="span">
                  {attribute.name}
                </Typography>
                <Typography component="p" variant="span">
                  {attribute.value}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell>
            <NavigatorLink href={bikePageurl}>{name}</NavigatorLink>
          </TableCell>
        </TableRow>
      </Table>

      <Box className="bd-reserveBtnBox">
        <Button
          fullWidth
          color="primary"
          className="bd-reserveBtn"
          component={NavigatorLink}
          href={bikePageurl}
          variant="contained">
          {t('fe_er_common_rental_card:reserve_now')}
        </Button>
      </Box>
    </StyledBox>
  );
};

export { BikeDetails };
