import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.root': {
    background: theme.palette.common.white,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    position: 'relative',
  },
  '& .cardFooterBox': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  '& .endDateLabel': {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginLeft: theme.spacing(2),
    '& span': {
      borderRadius: '4px',
    },
  },
}));

const EventCardSkeleton = () => (
  <StyledBox className="root">
    <Skeleton variant="rectangular" width="100%" height={230} />
    <Box className="cardFooterBox">
      <Skeleton variant="text" width="80%" height="70px" />
      <Box className="endDateLabel">
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
    </Box>
  </StyledBox>
);
export { EventCardSkeleton };
